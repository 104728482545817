<template>
  <v-row>
    <v-col cols="12" xs="3" md="3">
      <h1 class="primary--text">Create Job</h1>
    </v-col>
    <v-col cols="6" xs="3" md="3" offset-md="6" align-self="center">
      <v-btn
        small
        rounded
        class="primary float-right"
        to="/overview"
      >
        Back to Overview
      </v-btn>
    </v-col>

    <v-col cols="12" sm="12">
      <v-card>
        <v-skeleton-loader v-if="isLoading"
          type="card-heading, divider, list-item-three-line, image, list-item-three-line, divider, actions">
        </v-skeleton-loader>

        <v-form v-else class="mt-5" ref="orderForm" @submit.prevent="submitOrderClick()">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="">
                <stepper :stepper="stepper" :success="1"/>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h2 class="primary--text">JOB #{{ $tools.addLeadingZeros(quotation.id) }}</h2>
                <span>Welcome! You can upload your images here.</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="2">
                <h4>JOB NAME</h4>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <v-text-field
                  solo
                  v-model="orderForm.job_name"
                  class="pt-0"
                  placeholder="Job Name"
                  :counter="80"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="2">
                <h4>BUDGET AMOUNT</h4>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0">
                <v-text-field
                  solo
                  type="number"
                  v-model="orderForm.buyer_amount"
                  class="pt-0"
                  placeholder="Budget Amount"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="2">
                <h4>INSTRUCTIONS</h4>
              </v-col>
              <v-col cols="12" sm="10">
                <v-checkbox
                  v-for="item in ins_checkbox" :key="item.val"
                  v-model="orderForm.instructions"
                  class="mt-0 pt-0"
                  color="primary"
                  :value="item.val"
                  :label="item.text"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="2">
                <h4>ADVANCED INSTRUCTIONS</h4>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col v-for="item in adv_ins_checkbox" :key="item.val" cols="6">
                    <v-img :src="require(`@/assets/images/pages/${item.img}`)" class="gray-border"></v-img>
                    <v-checkbox
                      v-model="orderForm.adv_instructions"
                      class="pt-0"
                      color="primary"
                      :value="item.val"
                      :label="item.text"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="2"></v-col>
              <v-col cols="12" sm="10">
                <v-checkbox
                  v-model="custom_ins_check"
                  checked
                  class="pt-0"
                  color="primary"
                  label="CHECK TO ENTER YOUR CUSTOM INSTRUCTION"
                ></v-checkbox>

                <v-card class="gray-border" v-if="custom_ins_check==1">
                  <v-card-text class="pb-0">
                    <p>Please write your instructions here: i.e. clipping path, retain shadow, etc.</p>
                    <p>To ensure quoted processing is consistent with our designers capabilities, custom instructions require approval from an Doers Production Management.</p>
                    <p>You will receive a quote within 24 hours or the next business day.</p>
                    <v-textarea
                      v-model="orderForm.custom_instructions"
                      label="Your text..."
                      rows="4"
                      solo
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- file upload -->
            <upload
              status="created"
              :isClient="is_client"
              :isReviewer="is_reviewer"
              :method="orderForm.upload_via"
              :contents="contents"
              :path="`${quotation.id}/requested`"
              @getData="setData"
              @onClick="uploading">
            </upload>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions class="text-center d-block">
            <v-btn :disabled="!isUploaded" type="submit" class="primary rounded-pill px-5">
              REQUESTE QUOTATION
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CREATE_QUOTATION, REQUEST_QUOTATION } from "@/store/_actiontypes";
import { Role } from "@/utils/constant";
import json from "@/assets/static/data.json"
import Stepper from "@/components/overview/Stepper";
import Upload from "@/components/overview/Upload";


export default {
  components: {
    Stepper,
    Upload,
  },
  data(){
    return {
      isLoading: false,
      images: [],
      contents: {},
      progress: 0,
      custom_ins_check: 0,
      isUploaded: false,
      confirmation: false,
      orderForm: {
        job_name: "",
        instructions: [],
        adv_instructions: [],
        file_info: [],
        custom_instructions: "",
        buyer_amount: 0,
        upload_via: 1,
        revision: 0,
        status: "requested"
      },
      stepper: json.overview.stepper,
      ins_checkbox: json.overview.ins_checkbox,
      adv_ins_checkbox: json.overview.adv_ins_checkbox,
      is_client: this.$tools.authorize([Role.CLIENT]),
      is_reviewer: this.$tools.authorize([Role.REVIEW]),
    };
  },
	created(){
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
    this.createQuotation();
	},
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      quotation: (state) => state.quotation.data,
    }),
  },
  methods: {
    ...mapActions("quotation", [CREATE_QUOTATION, REQUEST_QUOTATION]),

    createQuotation(){
      this.isLoading = true;
      this.CREATE_QUOTATION().then(res => {
        this.isLoading = false;
        this.uploading();
      }).catch(() => {})
    },
    setData(data){
      this.isUploaded = data.isUploaded;
      this.orderForm.upload_via = data.method;
      this.orderForm.file_info = data.file_info;
    },
    uploading(tracking=false){
      if(tracking == "uploading" || this.quotation.content.length > 0){
        this.isUploaded = true;
      }
      if(this.quotation){
        this.contents["created"] = this.quotation.content;
      }
    },
    submitOrderClick(){
      this.isLoading = true;
      let quotationId = this.quotation.id;
      let type = "requested";
      let data = {
        job_name: this.orderForm.job_name,
        instructions: this.orderForm.instructions,
        adv_instructions: this.orderForm.adv_instructions,
        custom_instructions: this.custom_ins_check==1?this.orderForm.custom_instructions:"",
        buyer_amount: this.orderForm.buyer_amount,
        file_info: this.orderForm.file_info,
        revision: 0,
        upload_via: this.orderForm.upload_via,
        status: this.orderForm.status
      };

      this.REQUEST_QUOTATION({quotationId, type, data }).then((res) => {
        this.$router.push("/overview");
      }).catch((err) => {
        this.isLoading = false;
      });
    },

  }
};
</script>

<style scoped>
.bg-none{
  background: none !important;
  box-shadow: none !important;
}
.gray-border{
  border:1px solid gray;
}
</style>